import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { TGetGameDetails } from '../../common/types/bedev1Types';
import DeveloperProductCard from './DeveloperProductCard';
import { TDeveloperProduct } from '../types/developerProductTypes';
import PaginationBase from '../../../../../../Roblox.CoreUI.WebApp/Roblox.CoreUI.WebApp/js/react/pagination/components/Pagination';

type TDeveloperProductsGridProps = {
  translate: TranslateFunction;
  developerProducts: TDeveloperProduct[];
  gameDetails: TGetGameDetails;
  pendingDeveloperProducts: Map<number, number>;
  resultsPerPage: number;
  currentPage: number;
  onChangePage: (newPage: number) => void;
  numDeveloperProducts: number;
  rowsPerPage: number;
};

const DeveloperProductsGrid = ({
  translate,
  developerProducts,
  gameDetails,
  pendingDeveloperProducts,
  resultsPerPage,
  currentPage,
  onChangePage,
  numDeveloperProducts,
  rowsPerPage
}: TDeveloperProductsGridProps): JSX.Element => {
  const numPages = Math.ceil(numDeveloperProducts / resultsPerPage);
  const resultsPerRow = resultsPerPage / rowsPerPage;
  const pagination =
    numDeveloperProducts > resultsPerPage ? (
      <div className='overview-pagination-container'>
        <PaginationBase current={currentPage} total={numPages} onChange={onChangePage} hasNext />
      </div>
    ) : null;
  const RenderRow = ({ rowIndex }: { rowIndex: number }) => {
    const cards = developerProducts
      .slice(rowIndex * resultsPerRow, (rowIndex + 1) * resultsPerRow)
      .map(product => (
        <DeveloperProductCard
          pendingCount={pendingDeveloperProducts.get(product.productId) ?? 0}
          developerProduct={product}
          sellerName={gameDetails.creator.name}
          sellerId={gameDetails.creator.id}
          translate={translate}
          universeId={gameDetails.id}
          key={product.targetId}
        />
      ));
    // blank cards for consistent formatting
    if (cards.length > 0 && cards.length < resultsPerRow) {
      const blankCardCount = resultsPerRow - cards.length;
      const blankCards = Array.from({ length: blankCardCount }, (_, i) => i).map(cardNumber => (
        <span className='list-item' key={`blank-card-${cardNumber}`} />
      ));
      cards.push(...blankCards);
    }
    return <ul className='hlist store-developer-products-row'>{cards}</ul>;
  };

  return (
    <React.Fragment>
      {Array.from({ length: rowsPerPage }, (_, i) => i).map(rowNumber => (
        <RenderRow rowIndex={rowNumber} key={`dev-product-row-${rowNumber}`} />
      ))}
      {pagination}
    </React.Fragment>
  );
};

export default DeveloperProductsGrid;
