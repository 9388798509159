import React from 'react';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import GameStore from './GameStore';
import { gameStoreContainer } from '../common/constants/browserConstants';
import '../../../css/gameStore/gameStore.scss';

ready(() => {
  if (gameStoreContainer()) {
    // resolves to 'game-store-container'
    render(<GameStore />, gameStoreContainer());
  }
});
