import React, { useEffect, useMemo, useState } from 'react';
import { withTranslations, WithTranslationsProps } from 'react-utilities';
import { CurrentUser } from 'Roblox';
import metadataConstants from '../../gameDetails/constants/metadataConstants';
import { TGetGameDetails } from '../../common/types/bedev1Types';
import bedev1Services from '../../common/services/bedev1Services';
import { FeatureDeveloperProducts } from '../../common/constants/translationConstants';
import { TDeveloperProduct } from '../types/developerProductTypes';
import DeveloperProductsGrid from './DeveloperProductsGrid';
import { developerProductsTranslationConfig } from '../translation.config';
import developerProductServices from '../services/developerProductServices';

const DEVELOPER_PRODUCTS_PAGE_SIZE = 200;
const DEVELOPER_PRODUCTS_MAX_PAGE = 50; // to prevent infinite loading
const CARDS_PER_ROW_MIN = 3;
const CARDS_PER_ROW_MAX = 6;
const ROWS_PER_PAGE = 2;
const CARD_WIDTH = 166; // Approximate width of each card

const DeveloperProductsContainer = ({ translate }: WithTranslationsProps): JSX.Element => {
  const { universeId = '' } = metadataConstants.metadataData() || {};

  const [developerProducts, setDeveloperProducts] = useState<TDeveloperProduct[] | undefined>(
    undefined
  );
  const [pendingDeveloperProducts, setPendingDeveloperProducts] = useState<
    Map<number, number> | undefined
  >(undefined);

  const [productLoadPageNumber, setProductLoadPageNumber] = useState(1);
  const [loadingProducts, setLoadingProducts] = useState(true);

  const [gameDetails, setGameDetails] = useState<TGetGameDetails | undefined>(undefined);
  const authenticatedUserId: number = parseInt(CurrentUser.userId ?? '0', 10);

  // For pagination component logic
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState(CARDS_PER_ROW_MAX * ROWS_PER_PAGE);

  // Effect to set up the listener and update resultsPerPage on resize
  useEffect(() => {
    const updateResultsPerPage = () => {
      const screenWidth = window.innerWidth;
      const cardsToFit = Math.floor(screenWidth / CARD_WIDTH);
      const newResultsPerPage =
        Math.min(Math.max(cardsToFit, CARDS_PER_ROW_MIN), CARDS_PER_ROW_MAX) * ROWS_PER_PAGE;
      setResultsPerPage(newResultsPerPage); // Limit max to 6, or fewer if screen is smaller
      // go back to last valid page if current page is invalid
      if (developerProducts?.length) {
        const newTotalPages = Math.ceil(developerProducts.length / newResultsPerPage);
        setCurrentPage(oldCurrentPage => {
          if (oldCurrentPage > newTotalPages) {
            return newTotalPages;
          }
          return oldCurrentPage;
        });
      }
    };
    updateResultsPerPage(); // Initial update on load
    window.addEventListener('resize', updateResultsPerPage); // Update on window resize
    return () => {
      window.removeEventListener('resize', updateResultsPerPage); // Clean up on refresh or unmount
    };
  }, [developerProducts]);

  useEffect(() => {
    if (gameDetails?.rootPlaceId && authenticatedUserId) {
      developerProductServices
        .getPendingDeveloperProducts(gameDetails.rootPlaceId, authenticatedUserId)
        .then(data => setPendingDeveloperProducts(data))
        .catch(() => setPendingDeveloperProducts(new Map<number, number>()));
    }
  }, [gameDetails?.rootPlaceId, authenticatedUserId]);

  useEffect(() => {
    bedev1Services
      .getGameDetails(universeId)
      .then(data => setGameDetails(data))
      .catch(() => {
        // Currently no error handling for missing game details
      });
  }, [universeId]);

  useEffect(() => {
    if (loadingProducts && universeId !== '') {
      developerProductServices
        .getDeveloperProductsByUniverseId(
          parseInt(universeId, 10),
          productLoadPageNumber,
          DEVELOPER_PRODUCTS_PAGE_SIZE
        )
        .then(data => {
          setDeveloperProducts(existingProducts =>
            existingProducts === undefined ? data : existingProducts.concat(data)
          );
          if (
            data.length < DEVELOPER_PRODUCTS_PAGE_SIZE ||
            productLoadPageNumber >= DEVELOPER_PRODUCTS_MAX_PAGE // terminate after 10 pages to avoid runaway loop
          ) {
            setLoadingProducts(false);
          } else {
            setProductLoadPageNumber(productLoadPageNumber + 1);
          }
        })
        .catch(() => {
          setLoadingProducts(false);
          setDeveloperProducts([]);
        });
    }
  }, [loadingProducts, productLoadPageNumber, universeId]);

  const developerProductsSorted = useMemo(() => {
    if (developerProducts === undefined || pendingDeveloperProducts === undefined) {
      return undefined;
    }
    const withPending = developerProducts.filter(product =>
      pendingDeveloperProducts.has(product.productId)
    );
    const withoutPending = developerProducts.filter(
      product => !pendingDeveloperProducts.has(product.productId)
    );
    return withPending.concat(withoutPending);
  }, [developerProducts, pendingDeveloperProducts]);

  const pageProducts = useMemo(
    () =>
      developerProductsSorted?.slice(
        (currentPage - 1) * resultsPerPage,
        Math.min(currentPage * resultsPerPage, developerProductsSorted.length)
      ),
    [developerProductsSorted, currentPage, resultsPerPage]
  );

  /**
   * @jerrylai 9/19/2024: this will hide this component for non-enabled users. upon wide release, replace with the following:
   *   if (developerProducts === undefined || gameDetails === undefined) {
   *     return <Loading />;
   *   }
   *
   *   if (developerProducts.length === 0) {
   *     return (
   *       <div className='section-content-off'>
   *         {translate(FeatureDeveloperProducts.LabelNoProducts)}
   *       </div>
   *     );
   *   }
   */
  if (
    loadingProducts ||
    pageProducts === undefined ||
    developerProductsSorted === undefined ||
    developerProducts === undefined ||
    gameDetails === undefined ||
    pendingDeveloperProducts === undefined ||
    developerProducts.length === 0
  ) {
    return <React.Fragment />;
  }

  return (
    <React.Fragment>
      <div className='container-header'>
        <h2>{translate(FeatureDeveloperProducts.HeadingProducts)}</h2>
      </div>
      <DeveloperProductsGrid
        translate={translate}
        developerProducts={pageProducts}
        gameDetails={gameDetails}
        pendingDeveloperProducts={pendingDeveloperProducts}
        resultsPerPage={resultsPerPage}
        currentPage={currentPage}
        onChangePage={setCurrentPage}
        numDeveloperProducts={developerProducts.length}
        rowsPerPage={ROWS_PER_PAGE}
      />
    </React.Fragment>
  );
};

export default withTranslations(DeveloperProductsContainer, developerProductsTranslationConfig);
