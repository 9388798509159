import { EnvironmentUrls } from 'Roblox';
import UrlConfig from '../../../../../../Roblox.CoreScripts.WebApp/Roblox.CoreScripts.WebApp/js/core/http/interfaces/UrlConfig';

const { apiGatewayUrl } = EnvironmentUrls;

const url = {
  getDeveloperProductsForStorePage: (universeId: string): UrlConfig => ({
    url: `${apiGatewayUrl}/developer-products/v1/universes/${universeId}/store`,
    withCredentials: true
  }),
  getGameTransactions: {
    url: `${apiGatewayUrl}/developer-products/v1/game-transactions`,
    withCredentials: true
  }
};

export default {
  url
};
