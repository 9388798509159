import { TDeveloperProduct, TListDeveloperProductResponse } from '../types/developerProductTypes';

export default function processDeveloperProductResult(
  item: TListDeveloperProductResponse
): TDeveloperProduct | null {
  if (
    item === null ||
    !item.id ||
    item.priceInRobux === null ||
    item.priceInRobux === undefined ||
    !item.name ||
    !item.productId
  ) {
    return null;
  }
  return {
    targetId: item.id,
    productId: item.productId,
    name: item.name,
    priceInRobux: item.priceInRobux,
    Description: item.Description,
    iconImageAssetId: item.iconImageAssetId
  };
}
