import { httpService } from 'core-utilities';
import {
  TDeveloperProduct,
  TGetGameTransactionsParams,
  TGetGameTransactionsResponse,
  TListDeveloperProductParams,
  TListDeveloperProductResponseArray
} from '../types/developerProductTypes';
import processDeveloperProductResult from '../utils/processDeveloperProductResult';
import mapDeveloperProductsAndReceipts from '../utils/mapDeveloperProductsAndReceipts';
import developerProductConstants from '../constants/developerProductConstants';

export const getDeveloperProductsByUniverseId = async (
  universeId: number,
  page: number,
  pageSize: number
): Promise<TDeveloperProduct[]> => {
  const urlConfig = developerProductConstants.url.getDeveloperProductsForStorePage(
    universeId.toString()
  );
  const params: TListDeveloperProductParams = {
    page,
    pageSize,
    storePageEnabled: true
  };
  const result = await httpService
    .get<TListDeveloperProductResponseArray>(urlConfig, params)
    .then(response => response.data);
  return result
    .map(processDeveloperProductResult)
    .filter((item): item is TDeveloperProduct => item !== null); // map method returns null for invalid dev products
};
export const getPendingDeveloperProducts = async (
  rootPlaceId: number,
  playerId: number
): Promise<Map<number, number>> => {
  const urlConfig = developerProductConstants.url.getGameTransactions;
  const params: TGetGameTransactionsParams = {
    placeId: rootPlaceId,
    playerId,
    status: 'pending',
    locationType: 'ExperienceDetailPage'
  };
  const transactions = await httpService
    .get<TGetGameTransactionsResponse>(urlConfig, params)
    .then(response => response.data);
  return mapDeveloperProductsAndReceipts(transactions);
};

export default {
  getDeveloperProductsByUniverseId,
  getPendingDeveloperProducts
};
