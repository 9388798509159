type TConfig = {
  common: string[];
  feature: string;
};

export const gamePassTranslationConfig: TConfig = {
  common: ['CommonUI.Controls'],
  feature: 'Feature.GamePass'
};

export const developerProductsTranslationConfig: TConfig = {
  common: ['CommonUI.Controls'],
  feature: 'Feature.DeveloperProducts'
};
