import React, { useCallback, useMemo } from 'react';
import { Button } from 'react-style-guide';
import { TranslateFunction } from 'react-utilities';
import { createItemPurchase } from 'roblox-item-purchase';
import {
  Thumbnail2d,
  ThumbnailTypes,
  ThumbnailFormat,
  ThumbnailGamePassIconSize
} from 'roblox-thumbnails';
import { FeatureDeveloperProducts } from '../../common/constants/translationConstants';
import { TDeveloperProduct } from '../types/developerProductTypes';
import DeveloperProductPendingBadge from './DeveloperProductPendingBadge';

type TDeveloperProductCardProps = {
  developerProduct: TDeveloperProduct;
  translate: TranslateFunction;
  sellerName: string;
  sellerId: number;
  universeId: number;
  pendingCount: number;
};

const DeveloperProductCard = ({
  developerProduct,
  sellerName,
  sellerId,
  universeId,
  translate,
  pendingCount
}: TDeveloperProductCardProps): JSX.Element | null => {
  const [ItemPurchase, itemPurchaseService] = createItemPurchase();

  const thumbnailComponent = useMemo(() => {
    return (
      <Thumbnail2d
        type={ThumbnailTypes.developerProductIcon}
        size={ThumbnailGamePassIconSize.size150}
        targetId={developerProduct.targetId}
        format={ThumbnailFormat.webp}
        altName={developerProduct.name}
        imgClassName='thumbnail'
      />
    );
  }, [developerProduct.name, developerProduct.targetId]);

  const onPurchase = useCallback(() => {
    itemPurchaseService.start();
  }, [itemPurchaseService]);

  return (
    <li className='list-item developer-product-tile'>
      <div className='store-card'>
        <div className='store-product-card-thumbnail'>
          {thumbnailComponent}
          {pendingCount ? (
            <DeveloperProductPendingBadge
              count={pendingCount}
              translate={translate}
              productId={developerProduct.productId}
            />
          ) : null}
        </div>
        <div className='store-product-card-caption'>
          <div className='store-product-card-name' title={developerProduct.name}>
            {developerProduct.name}
          </div>
          <div className='store-card-price'>
            <span className='icon-robux-16x16' />
            <span className='text-robux'>{developerProduct.priceInRobux}</span>
          </div>
          <div className='store-card-footer'>
            <Button
              data-product-id={developerProduct.productId}
              onClick={onPurchase}
              className='PurchaseButton btn-buy-md btn-full-width rbx-gear-passes-purchase'>
              <span>{translate(FeatureDeveloperProducts.LabelBuy)}</span>
            </Button>
            <ItemPurchase
              translate={translate}
              thumbnail={thumbnailComponent}
              productId={developerProduct.productId}
              assetName={developerProduct.name}
              assetType='Product'
              sellerName={sellerName}
              expectedCurrency={1}
              expectedSellerId={sellerId} // sellerId is not used in dev product purchase request
              expectedPrice={developerProduct.priceInRobux}
              saleLocationId={universeId}
              showSuccessBanner={false}
            />
          </div>
        </div>
      </div>
    </li>
  );
};

export default DeveloperProductCard;
